#app {
  margin: 0 auto;
  padding: 0;
  height: 100%;
  background-color: #343a40;
}

@media (max-width: 1080px) {
  .paragrafoAccordion {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  .paragrafoAccordion {
    font-size: 87.5%;
  }
}

@media (max-width: 540px) {
  .paragrafoAccordion {
    font-size: 87.5%;
  }
}

@media (max-width: 411px) {
  .paragrafoAccordion {
    font-size: 87.5%;
  }
}

@media (max-width: 414px) {
  .paragrafoAccordion {
    font-size: 87.5%;
  }
}

@media (max-width: 360px) {
  .paragrafoAccordion {
    font-size: 87.5%;
  }
}

@media (max-width: 320px) {
  .paragrafoAccordion {
    font-size: 87.5%;
  }
}

.banner {
  background-color: #f2f2f2;

  text-align: center;
  padding: 20px;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Isso alinhará os elementos totalmente à esquerda e à direita */
  margin: 0 auto;
  padding: 0 20px; /* Adicionando um espaço entre o conteúdo e as bordas */
}

.left-content {
  display: flex;
  flex-direction: row;
}

.logo {
  max-width: 100%;
  height: auto;
  
}

.descricao {
  display: flex;
  flex-direction: column;
  text-align: left;
 
}

.right-content {
  text-align: center;
}

.banner h1 {
  font-size: 36px;
  margin: 0;
}

.banner p {
  font-size: 18px;
  margin: 10px 0;
}

.download-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 20px;
  margin: 10px;
}

.download-button:hover {
  background-color: #1c2833;
}
