body {
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #343a40;
  align-items: center;
  justify-content: center;
}
#root {
  margin: 0 auto;
  width: 100%;
  background-image: url("./assets/logo/logo_supply_watermarket2.png");
  background-position: center;
  background-repeat:no-repeat;
  background-size: cover;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }
p {
  line-height: 1.5;
  margin: 0 0 1rem 0;
}