.radioButton {
  margin-bottom: 1rem;
}

th {
  font-size: 0.6rem;
}
td {
  padding-right: 0.6rem;

  font-size: 0.6rem;
}

.containerTable {
  height: 50vh;
  overflow-y: auto;
  scrollbar-width: none;
}

