
.custom-marker {
  border-radius: 10;
}
.timeline-demo .custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

.timeline-demo .p-timeline-event-content,
.timeline-demo .p-timeline-event-opposite {
  line-height: 1;
}

@media screen and (max-width: 960px) {
  .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) {
    flex-direction: row !important;
  }
  .timeline-demo
    .customized-timeline
    .p-timeline-event:nth-child(even)
    .p-timeline-event-content {
    text-align: left !important;
  }
  .timeline-demo .customized-timeline .p-timeline-event-opposite {
    flex: 0;
  }
  .timeline-demo .customized-timeline .p-card {
    margin-top: 1rem;
  }
}

.card {
  margin-bottom: 2rem;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.customer-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.customer-badge.status-qualified {
  background-color: #c8e6c9;
  color: #256029;
}

.customer-badge.status-unqualified {
  background-color: #ffcdd2;
  color: #c63737;
}

.customer-badge.status-negotiation {
  background-color: #feedaf;
  color: #8a5340;
}

.customer-badge.status-new {
  background-color: #b3e5fc;
  color: #23547b;
}

.customer-badge.status-renewal {
  background-color: #eccfff;
  color: #694382;
}

.customer-badge.status-proposal {
  background-color: #ffd8b2;
  color: #805b36;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.product-badge.status-instock {
  background: #c8e6c9;
  color: #256029;
}

.product-badge.status-outofstock {
  background: #ffcdd2;
  color: #c63737;
}

.product-badge.status-lowstock {
  background: #feedaf;
  color: #8a5340;
}

.order-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.order-badge.order-delivered {
  background: #c8e6c9;
  color: #256029;
}

.order-badge.order-cancelled {
  background: #ffcdd2;
  color: #c63737;
}

.order-badge.order-pending {
  background: #feedaf;
  color: #8a5340;
}

.order-badge.order-returned {
  background: #eccfff;
  color: #694382;
}

.image-text {
  vertical-align: middle;
  margin-left: 0.5rem;
}

.p-multiselect-representative-option {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-representative-option img {
  vertical-align: middle;
  width: 24px;
}

.p-multiselect-representative-option span {
  margin-top: 0.125rem;
}

.p-column-filter {
  width: 100%;
}

.country-item {
  display: flex;
  align-items: center;
}

.country-item img.flag {
  width: 18px;
  margin-right: 0.5rem;
}

.flag {
  vertical-align: middle;
}

span.flag {
  width: 44px;
  height: 30px;
  display: inline-block;
}

img.flag {
  width: 30px;
}

@keyframes neon {
  from {
    filter: drop-shadow(0 0 0 var(--cor--neon));
  }
  to {
    filter: drop-shadow(0 0 20px var(--cor--neon));
  }
}

svg {
  animation: neon 1s alternate infinite ease-in-out;
}



.iconAlertCircle {
  --cor--neon: yellow;
}

.iconAlertTriangle {
  --cor--neon: red;
}

.iconCloudOff {
  --cor--neon: black;
}

.iconCheck {
  --cor--neon: green;
}
.iconBatteryCharging {
  --cor--neon: #041525;
}

.iconBatteryHalf {
  --cor--neon: #05ca05;
}

.faLeaf {
  --cor--neon:#077707;
}
.faPlug {
  --cor--neon: #a76503;
}




.busca{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5rem;
}
.busca div{
  display: flex;
  width: 10rem;
  height: 1.95rem; 
}
